<template>
    <div>
      <vue-apple-login v-if="!signedIn"
          color="black"
          :border="true"
          type="sign in"
          width="200"
          height="36"
          :onSuccess="onSuccess"
          :onFailure="onFailure"
      ></vue-apple-login>
      <button v-if="signedIn" v-on:click="onSuccessLogout()" class="login-button">
        Sign out with Apple
      </button>
    </div>
  </template>
  
  <script>
      export default {
          data() {
              return {
                  signedIn: false,
              }
          },
          methods: {
              onSuccess(data) {
                  console.log(data);
                  this.signedIn = true;
              },
              onSuccessLogout(){
                  this.signedIn = false;
              },
              onFailure(error) {
                  console.log(error);
              },
          }
      }
  </script>
  
  <style scoped>
      button.login-button {
        background-color: white;
        height: 32px;
        width: 200px;
        border-radius: 5px;
        border: 0px;
        font-size: 12px;
        font-weight: 500;
        margin: 5px;
      }
      button.login-button svg{
        height: 15px;
        margin-top: -3px;
        margin-right: 3px;
      }
  </style>